.revisao-oficial .container-fluid {
  width: 100%;
}

.buttons button + button {
  margin-left: 35px;
}

.img-right img {
  clip-path: polygon(80% 0, 100% 2%, 100% 100%, 0 100%, 0 2.5%);
}

.img-right .img-content {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-45%, -50%);
}

.img-right .img-content .ate {
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  margin-top: 16px;
  margin-left: 18px;
}
.img-right .img-content .desconto {
  font-size: 130px;
  line-height: 115px;
  font-weight: 700;
}
.img-right .img-content .onde {
  font-size: 39px;
  font-weight: 400;
  margin-right: 0;
  text-align: end;
}

/* .img-right .img-content .ate {
  font-size: 30px;
  font-weight: 400;
  position: absolute;
  margin-top: 10px;
  margin-left: 15px;
}
.img-right .img-content .desconto {
  font-size: 170px;
  line-height: 135px;
  font-weight: 700;
}
.img-right .img-content .onde {
  font-size: 52px;
  font-weight: 400;
  margin-right: 0;
  text-align: end;
} */

.accordion-item {
  background-color: #fff;
  border-bottom: 1px solid #1a1a1a !important;
}

.accordion-flush .accordion-item:first-child {
  border-top: 1px solid #1a1a1a !important;
}

.accordion-header .accordion-button {
  font-size: 17px;
  font-weight: 700;
}

.accordion-button::after {
  width: auto;
  height: auto;
  content: "+";
  font-size: 24px;
  background-image: none;
  color: #e2231a;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: rotate(-180deg);
}
.accordion-button:not(.collapsed) {
  color: #e2231a;
  background-color: #fff;
}

.accordion-body {
  font-family: "Roboto", sans-serif;
  padding: 0.5rem 1rem 2rem 1rem;
}

.accordion-button:focus {
  border: 0;
  box-shadow: none;
}

@media (min-width: 1600px) {
  .revisao-oficial .text-left {
    padding-left: 16%;
  }
  .img-right img {
    padding-left: 10%;
  }
}

@media (max-width: 991px) {
  .img-right img {
    clip-path: none;
    margin-top: 50px;
  }
}

@media (max-width: 1300px) {
  .img-right .img-content .ate {
    font-size: 16px;
  }
  .img-right .img-content .desconto {
    font-size: 100px;
    line-height: 90px;
  }
  .img-right .img-content .onde {
    font-size: 30px;
  }
}

/* @media only screen and (max-width: 991px) and (min-width: 567px) {
  .img-right .img-content .ate {
    font-size: 16px;
  }
  .img-right .img-content .desconto {
    font-size: 100px;
    line-height: 90px;
  }
  .img-right .img-content .onde {
    font-size: 30px;
  }
} */

@media (max-width: 567px) {
  .img-right .img-content .ate {
    font-size: 12px;
    margin-left: 10px;
  }
  .img-right .img-content .desconto {
    font-size: 80px;
    line-height: 75px;
  }
  .img-right .img-content .onde {
    font-size: 23px;
  }
}
