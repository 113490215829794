.box-vantagens {
  font-weight: 400;
}
.box-vantagens .title-vantagens {
  font-size: 18px;
  letter-spacing: 0.5px;
}

.box-vantagens img {
  width: 12px;
  height: 12px;
  filter: brightness(0) invert(1);
}

.sobre-nos-img-right::before {
  content: "";
  position: absolute;
  height: 90%;
  width: 50%;
  top: 50%;
  right: 0;
  transform: translate(-0%, -50%);
  background: #1a1a1a;
  z-index: -1;
}

.bg-sobre-nos-servicos {
  background: linear-gradient(90deg, #1a1a1a 50%, #565656 50%);
}

.box-crop {
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.box-crop .first-title {
  font-size: 50px;
  line-height: 1;
}

.box-crop .sub-title {
  font-size: 25px;
}

.box-crop img {
  width: 75px;
  height: 75px;
  filter: brightness(0) invert(1);
}

.box-crop button {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-top: 15px;
}

.sobre-nos-servicos .col-lg-3:first-child .box-crop {
  background: #1a1a1a;
}
.sobre-nos-servicos .col-lg-3:nth-child(2) .box-crop {
  background: #353535;
}
.sobre-nos-servicos .col-lg-3:nth-child(3) .box-crop {
  background: #474747;
}
.sobre-nos-servicos .col-lg-3:nth-child(4) .box-crop {
  background: #565656;
}

.box-compromisso .title {
  font-size: 25px;
}

.box-compromisso {
  padding: 30px;
}

.bg-compromisso {
  width: 450px;
  max-width: 50%;
  right: -100px;
  z-index: -1;
  transform: scaleX(-1);
}

@media (min-width: 1400px) {
  .box-crop {
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
    width: 111%;
  }

  .bg-sobre-nos-servicos .container {
    max-width: 1366px;
  }

  .sobre-nos-servicos .col-lg-3:first-child .box-crop {
    padding: 50px 50px 50px 29px;
  }
  .sobre-nos-servicos .col-lg-3 .box-crop {
    padding: 0 50px 0 50px;
  }
}

@media (max-width: 1400px) {
  .box-crop {
    padding: 30px;
  }
  .box-crop .first-title {
    font-size: 35px;
    line-height: 1;
  }
}

@media (min-width: 991px) {
  .box-vantagens {
    width: 105%;
  }
  .sobre-nos-img-right {
    margin-bottom: -1px;
  }
}

@media (max-width: 991px) {
  .bg-sobre-nos-servicos {
    background: none;
  }
}
