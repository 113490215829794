.background-cover img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.content-modulo{
  position: relative;
  z-index: 99;
  padding: 7% 17% ;
}
.content-modulo h2{
  font-size: 42px;
}
.div-clip-path{
  clip-path: polygon(2% 0px, 100% 0%, 100% 100%, 0.00% 100%);
  overflow: hidden;
  position: relative;
  height: 100%;
}
@media (max-width: 768px)
{
  .div-clip-path{
    clip-path: polygon(0% 0px, 100% 0%, 100% 100%, 0.00% 100%);
  }
}