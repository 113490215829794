.btn {
  border-radius: 0;
  font-size: 15px;
  font-weight: 600;
}
.btn-red {
  background: #e2231a;
  color: #fff;
}
.btn-grey {
  background: #e2e2e2;
  color: #1a1a1a;
}
.btn-grey.icon-s {
  width: 100%;
}
.btn-grey.icon img {
  height: 40px;
  margin: 1rem auto;
}
.btn-grey h6 {
  font-weight: 700;
  font-size: 14px;
}
.btn.btn-grey.icon {
  width: 100%;
}
.btn-grey.icon:hover {
  background: #e2231a;
  color: #fff;
}
.btn-grey.icon:hover img {
  filter: brightness(0) invert(1);
}
.btn-white {
  background: #fff;
  color: #e2231a;
}
.btn-black {
  background: #1a1a1a;
  color: #fff;
}
.btn-white-text-dark {
  background: #fff;
  color: #1a1a1a;
}
.btn-saber-mais {
  background: none;
  padding: 0;
  border-bottom: 2px solid #e2231a;
  font-weight: 400;
}
.btn-red:hover {
  background: #bb1911;
  color: #fff;
}

.btn-black:hover {
  background: #000;
  color: #fff;
}

.btn-white:hover {
  background: #515151;
  color: #fff;
}
.btn-white-text-dark:hover {
  background: #1a1a1a;
  color: #fff;
}
