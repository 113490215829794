.marcacao .title-form {
  font-size: 45px;
}

.marcacao .subtitle {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2px;
}

.marcacao .title {
  font-size: 44px;
}

.step {
  text-align: center;
  background: #1a1a1a;
  padding: 45px 15px;
  margin-bottom: 20px;
}

.step .number {
  color: #e1251b;
  font-size: 58px;
  line-height: 48px;
  font-weight: 900;
  margin-bottom: 15px;
}

.step .name {
  color: #898a8d;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
}

.step.active {
  background: #e1251b;
}

.step.active .number {
  color: #1a1a1a;
}

.step.active .name {
  color: #fff;
}

.label {
  font-size: 15px;
  letter-spacing: 1px;
  margin-bottom: 5px;
}
.form-control,
.form-select,
.form-check,
.form-check-input {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  border-color: #1a1a1a;
  border-radius: 0 !important;
  color: #898a8d;
}
.form-check label {
  padding-top: 3px;
}
.form-control.outro {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.steps-form textarea {
  height: 100px;
}

.form-control:focus,
.form-select:focus,
.form-check-input:focus {
  border-color: #e2231a;
  box-shadow: none;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url(../../img/forms/steps-check-checked.svg);
  background-size: 10px;
  background-color: #e2231a;
  border-color: #e2231a;
}

.marcacao .steps-form .marcacao-buttons {
  margin-top: 30%;
}

.marcacao .steps-form.steps-2-form .marcacao-buttons {
  margin-top: 10%;
}

.marcacao .steps-form .marcacao-buttons button {
  padding: 15px 50px;
}

.marcacao .steps-form .marcacao-buttons button + button {
  margin-left: 15px;
}

.input-group-text {
  font-family: 'Poppins', sans-serif;
  color: #e2e2e2;
  text-transform: uppercase;
  font-size: 11px;
  background-color: #1a1a1a;
  border: 1px solid #1a1a1a;
  border-radius: 0;
  min-width: 150px;
  align-items: center;
}

@media (max-width: 768px) {
  .step {
    width: 25%;
    padding: 15px;
    margin-right: 10px;
    margin-bottom: 0;
  }
  .step:last-child {
    margin-right: 0;
  }
  .marcacao .steps-form .marcacao-buttons {
    margin-top: 10%;
  }
}

@media (max-width: 768px) {
  .step .number {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 5px;
  }
  .step .name {
    font-size: 13px;
  }
}
