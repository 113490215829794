.service .category {
  width: 90%;
  height: 82px;
  margin: -25px auto 0;
  z-index: 999;
  padding: 15px 20px;
  position: relative;
}

.service .category-text {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
}

.service .resume {
  width: 80%;
  margin: auto;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  overflow: hidden;
  /*display: -webkit-box;*/
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.footer-no-clip + .section > footer {
  clip-path: none;
}