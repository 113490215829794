.pagination {
    font-size: 20px;
    font-weight: 500;
}

.pagination .page-item {
    margin: 0 7px;;
}

.pagination .page-link,
.pagination .active .page-link {
    background-color: transparent;
    color: #1a1a1a;
}

.pagination .active .page-link,
.pagination .page-link:hover {
    cursor: pointer;
    border-bottom: 2px solid #e1251b !important;
}

.pagination .page-link img {
    width: 30px;
}

.pagination .page-link img.previous {
    transform: rotate(180deg);
}

.pagination .previous,
.pagination .next,
.pagination .previous:hover,
.pagination .next:hover {
    padding: 5px !important;
    margin: 0 10px;
    border-bottom: 0 !important;
}