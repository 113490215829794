.pneus-img-right img {
  clip-path: polygon(75% 0, 100% 2%, 100% 100%, 0 100%, 0 3%);
}

.pressao-pneus {
  clip-path: polygon(52% 4%, 81% 0, 100% 4%, 100% 100%, 0 100%, 0 5%, 23% 0);
  background: #ececec;
}

.pressao-pneus .subtitle {
  font-size: 35px;
  font-weight: 500;
}

.conditions img {
  width: 12px;
  height: 12px;
}

.separator {
  margin-top: -16px !important;
}

.separator .content-modulo {
    padding: 10% 20%;
}
