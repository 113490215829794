footer {
  clip-path: polygon(0 8%, 20% 2%, 44% 7%, 77% 0%, 100% 7%, 100% 101%, 0 101%);
  color: #FFF;
  padding: 75px 0 15px;
}

.logo-footer {
  max-width: 190px;
}

.icon-footer {
  height: 30px;
}

footer h4 {
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 5px;
}

footer h5 {
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 1rem;
}

footer .btn-saber-mais {
  font-weight: 500;
  font-size: 15px;
  line-height: 0;
  padding: 0 3px;
  color: #fff;
  border: 0;
  border-bottom: 5px solid #e2231a;
}

footer .btn-saber-mais:hover {
  color: #fff;
}

.footer-bg {
  width: 330px;
  max-width: 50%;
  bottom: -28%;
  right: 14%;
  opacity: .04;
  z-index: -1;
}

.menu-footer .nav-link {
  display: block;
  padding: 0;
  margin-bottom: 0.5rem;
  color: #FFF;
  font-size: 14px;
}

input.newsletter {
  border: 1px solid #848484;
  border-radius: 0;
  font-size: 13px;
  background: #1a1a1a;
}

.btn-newsletter {
  font-size: 13px;
  font-weight: 500;
}

.social-networks img {
  max-width: 30px;
}

@media (max-width: 768px){
  footer {
    clip-path: none;
  }
}