.banner-int-small {
  height: 20vh;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 75% 75%, 45% 100%, 20% 86%, 0% 100%);
}

.banner-int-small img{
  width: 100%;
  height: 100%;
  object-fit: cover;  
  object-position: bottom;
}

.banner-int-small h2.title-banner{
  font-size: 70px;
  font-weight: 700;
  color: #FFF;
}
@media (max-width: 768px)
{
  .banner-int-small {
    height: 25vh;
}
  .banner-int-small h2.title-banner{
    font-size: 40px;
  }
  .banner-int-small .btn{
    font-size: 13px;
  }
}