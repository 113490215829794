.block-side-map .title {
  font-size: 35px;
}

.block-side-map .title {
  font-size: 35px;
}

.block-side-map .oficina {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  border: 1px solid #000;
}

.block-side-map .oficina .name {
  font-size: 23px;
}

.block-side-map .oficina .details img {
  width: 14px;
  max-height: 19px;
}

.block-side-map .oficina .details img.icon-map {
  width: 13px;
  max-height: 16px;
}

.block-side-map .oficina .card-body {
  padding: 45px;
}

.block-side-map .oficina .details {
  font-size: 16px;
}

.block-side-map .oficina .horario {
  font-size: 18px;
}

.block-side-map .img-oficina img {
  object-fit: cover;
  height: 100%;
}

.block-side-map .buttons button + button {
  margin-left: 35px;
}

@media (min-width: 1400px) {
    .redes-oficinas .content-left {
      margin-left: 3%;
      width: 40.4%;
    }
  }
  