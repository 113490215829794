.list-square .btn.btn-red:hover, .list-square .btn.btn-black:hover{
  color: #FFF;
  opacity: 95%;
}
.list-square .btn.btn-grey:hover{  
  opacity: 95%;
}
.list-square .icon-btn{
  max-width: 70px;
}
.list-square h2{
  font-size: 32px;
  font-weight: 700;
}
.list-square .font-90{
  line-height: 0.7;
  font-weight: 700;
  width: auto;
}