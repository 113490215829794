.form-title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.box-info {
  border: 1px solid red;
}

.box-info .title {
  color: #e2e2e2;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.box-info .content {
  font-size: 18px;
}

.box-info img {
  width: 40px;
  height: 40px;
}

.form-contactos textarea {
  height: 150px;
}
