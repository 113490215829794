.acordos .col-lg-6 {
  margin-bottom: 70px;
}

.acordo {
  z-index: 0;
}

.acordo .logo {
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  top: -20%;
  left: 0;
  right: 0;
  text-align: center;
}

.acordo .title {
  font-size: 31px;
}

.acordo .card-body {
  padding: 120px 80px 40px 80px;
}

.acordo .acordo-content img {
  width: 12px;
  height: 12px;
}

.acordos-overlay-text {
  font-size: 145px;
  line-height: 100px;
  z-index: -1;
}

.content-subfooter .h-105 {
  margin-top: -17px;
  z-index: 99;
  clip-path: polygon(0 0, 97% 0%, 100% 100%, 0% 100%);
}

.content-subfooter .h-105 + .content-modulo {
  padding: 7% 28%;
}

.content-subfooter .content-modulo {
  padding: 7% 25%;
}
.content-subfooter {
  margin-top: 10px;
}
.content-subfooter + .section > footer {
  clip-path: none;
}

@media (max-width: 1400px) {
  .acordos-overlay-text {
    font-size: 100px;
    line-height: 85px;
  }
}

@media (max-width: 1200px) {
  .acordo .logo {
    top: -13%;
  }
  .content-subfooter .h-105 {
    margin-top: -18px;
  }
  .content-subfooter .h-105 + .content-modulo {
    padding: 7% 18%;
  }
}

@media (max-width: 991px) {
  .acordo .logo {
    top: -25%;
  }
  .acordos-overlay-text {
    font-size: 140px;
    line-height: 100px;
  }
  .content-subfooter .h-105 + .content-modulo {
    padding: 12%;
  }

  .content-subfooter .content-modulo {
    padding: 15%;
  }
}

@media (max-width: 768px) {
  .acordo .logo {
    top: -20%;
  }
  .acordos-overlay-text {
    font-size: 80px;
    line-height: 60px;
  }
  .content-subfooter {
    margin-top: 0;
  }
}

@media (max-width: 576px) {
  .acordo .logo {
    top: -13%;
  }
}

@media (max-width: 400px) {
  .acordos-overlay-text {
    font-size: 50px;
    line-height: 40px;
  }
}
