.background-conselhos{
    width: 450px;
    max-width: 50%;
    left: -100px;
    z-index: -1;
  }
  .title-lines{
    font-size: 55px;
    font-weight: 700;
  }
  .title-lines:before, .title-lines:after{
    content: '';
    height: 4px;
    width: 75px;
    background: #e2231a;
    display: inline-block;  
    position: relative;
    vertical-align: middle;
    margin: 0 1rem;
  }
  .concelho p{
    font-size: 14px;
  }
  .concelho .category{  
    margin-top: -1rem;
    position: relative;
    display: inline-block;
    z-index: 99;
    padding: 7px;
    margin-left: .5rem;
    font-size: 14px;
    overflow: hidden;
  }
  .concelho h6{
    font-size: 22px;
    font-weight: 700;
  }
  