.pack .title {
  font-size: 30px;
  font-weight: 700;
}
.pack .price-block .since {
  font-size: 17px;
  margin-bottom: -10px;
}

.pack .price-block .price {
  font-size: 90px;
  margin-left: -5px;
}

.pack .price-block .price .euro {
  font-size: 50px;
}

.pack .features {
  font-family: "Roboto", sans-serif;
}

.pack .features img {
  width: 12px;
  height: 12px;
}

.pack-details .title {
  font-size: 24px;
}

.pack-details img {
  width: 12px;
  height: 12px;
}

.revision .title {
  font-size: 25px;
}

.revision .icon {
  width: 35px;
  height: 35px;
}

.revision .title .small {
  font-size: 13px;
  margin-left: 5px;
}

.revision .form-check.price .line::after {
  content: "";
  position: absolute;
  border-bottom: 1px dashed #e2e2e2;
  margin: auto;
  width: 88%;
  top: 52%;
}

.revision .form-check.price .line label {
  background: #fff;
  z-index: 1;
}

.revision .small-label {
  font-size: 12px;
}

.revision .form-check.price .price {
  font-size: 32px;
  line-height: 1;
  background: #fff;
  z-index: 1;
}
.line-total {
  border-bottom: 1px dashed #e2e2e2;
}

.revision .final-price {
  font-size: 90px;
}

.revision .iva {
  color: #898a8d;
  font-size: 11px;
  margin-right: 15%;
}

.note-revision-pack {
  font-size: 12px;
}