.banner-int {
  height: 45vh;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 77% 94%, 44% 100%, 20% 96%, 0 100%);
}

.banner-int img{
  width: 100%;
  height: 100%;
  object-fit: cover;  
}

.banner-int h2.title-banner{
  font-size: 70px;
  font-weight: 700;
  color: #FFF;
}
@media (max-width: 768px)
{
  .banner-int {
    height: 25vh;
}
  .banner-int h2.title-banner{
    font-size: 40px;
  }
  .banner-int .btn{
    font-size: 13px;
  }
}