.form-title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.form-join-us textarea {
  height: 150px;
}

.box-join {
  border: 1px solid red;
}

.box-join img {
  width: 60px;
  height: 60px;
}

.box-join .title {
  font-size: 22px;
}