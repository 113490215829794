.navbar {
  background: #1a1a1a;
  border-bottom: 2px solid #e2231a;
}
.navbar-brand {
  height: 90px;
  padding: 0;
}
.navbar-brand img {
  width: 250px;
  background: #fff;
  padding: 10% 15%;
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  z-index: 9;
}
.navbar .nav-item {
  margin: auto 0.5rem;
}
.navbar .nav-item a.nav-link,
.dropdown-menu .dropdown-item {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s;
}

.navbar .login img {
  width: 25px;
  height: 25px;
}
.navbar .nav-item a.nav-link:hover,
.navbar .nav-item a.nav-link.active,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
  color: #e2231a;
  background: transparent;
}
.navbar-nav .btn {
  font-size: 13px;
  padding: 0.3rem 1rem;
}

.dropdown-menu {
  background-color: #1a1a1a;
  border: 1px solid #424242;
  border-radius: 0;
}

.dropdown-menu .dropdown-item {
  margin: 7px 0;
}

@media (max-width: 1400px) {
  .navbar-nav .dropdown-menu {
    text-align: end;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
  }
}

@media (max-width: 1400px) {
  .navbar-brand {
    height: 75px;
  }
  .navbar-brand img {
    width: 200px;
  }
  nav ul.navbar-nav {
    margin-top: 15px;
  }
  nav ul.navbar-nav li.nav-item,
  nav ul.navbar-nav li.nav-item-button {
    text-align: right;
  }
}

@media all and (min-width: 1400px) {
  .dropdown-menu li {
    position: relative;
  }
  .nav-item .submenu {
    /* display: none;
    position: absolute; */
    left: 100%;
    top: -7px;
  }
  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }
  /* .dropdown-menu > li:hover > .submenu {
    display: block;
  } */
}

@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: 0.5rem;
  }
}
