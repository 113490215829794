.campanhas .col-lg-6 {
    margin-bottom: 70px;
}

.campanhas {
    z-index: 0;
}

    .campanhas .logo {
        width: 45%;
        margin-left: auto;
        margin-right: auto;
        top: -20%;
        left: 0;
        right: 0;
        text-align: center;
    }

    .campanhas .title {
        font-size: 31px;
    }

    .campanhas .card-body {
        padding: 120px 80px 40px 80px;
    }

    .campanhas .campanhas-content img {
        width: 12px;
        height: 12px;
    }

.campanhas-overlay-text {
    font-size: 97px;
    line-height: 125px;
    z-index: -1;
}

.content-subfooter .h-105 {
    margin-top: -17px;
    z-index: 99;
    clip-path: polygon(0 0, 97% 0%, 100% 100%, 0% 100%);
}

    .content-subfooter .h-105 + .content-modulo {
        padding: 7% 28%;
    }

.content-subfooter .content-modulo {
    padding: 7% 25%;
}

.content-subfooter {
    margin-top: 10px;
}

    .content-subfooter + .section > footer {
        clip-path: none;
    }

@media (max-width: 1400px) {
    .campanhas-overlay-text {
        font-size: 100px;
        line-height: 85px;
    }
}

@media (max-width: 1200px) {
    .campanhas .logo {
        top: -13%;
    }

    .content-subfooter .h-105 {
        margin-top: -18px;
    }

        .content-subfooter .h-105 + .content-modulo {
            padding: 7% 18%;
        }
}

@media (max-width: 991px) {
    .campanhas .logo {
        top: -25%;
    }

    .campanhas-overlay-text {
        font-size: 140px;
        line-height: 100px;
    }

    .content-subfooter .h-105 + .content-modulo {
        padding: 12%;
    }

    .content-subfooter .content-modulo {
        padding: 15%;
    }
}

@media (max-width: 768px) {
    .campanhas .logo {
        top: -20%;
    }

    .campanhas-overlay-text {
        font-size: 80px;
        line-height: 60px;
    }

    .content-subfooter {
        margin-top: 0;
    }
}

@media (max-width: 576px) {
    .campanhas .logo {
        top: -13%;
    }
}

@media (max-width: 400px) {
    .campanhas-overlay-text {
        font-size: 50px;
        line-height: 40px;
    }
}
