.banner {
    /*height: 75vh;*/
}

    .banner img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        /*clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 77% 95%, 44% 100%, 20% 98%, 0% 100%, 0% 0%);*/
        clip-path: polygon(0 0, 100% 0, 100% 100%, 77% 94%, 44% 100%, 20% 96%, 0 100%);
    }

    .banner h2.title-banner {
        font-size: 70px;
        font-weight: 700;
        color: #FFF;
    }

    .banner h2.title-banner-carousel {
        font-size: 70px;
        font-weight: 700;
        color: #FFF;
    }

@media (max-width: 768px) {
    .banner h2.title-banner {
        font-size: 40px;
    }

    .banner .btn {
        font-size: 13px;
    }

    .banner h2.title-banner-carousel {
        font-size: 30px;
    }

    .banner .btn-carousel {
        font-size: 13px;
    }
}

@media (max-width: 400px) {
    .banner h2.title-banner {
        font-size: 40px;
    }

    .banner .btn {
        font-size: 13px;
    }

    .banner h2.title-banner-carousel {
        font-size: 20px;
    }

    .banner .btn-carousel {
        font-size: 13px;
    }
}
