.acordos {
  margin-top: -2rem;
  position: relative;
  z-index: 9;
}

.acordos .bg-darker {
  padding: 0 20% 0 5%;
  clip-path: polygon(2% 0%, 100% 0, 100% 100%, 0% 100%);
}

.img-acordo {
  height: 75px;
}

@media (max-width: 1366px) {
  .acordos .bg-darker {
    padding: 0 10% 0 2%;
  }
}

@media (max-width: 991px) {
  .acordos .bg-darker {
    padding: 0 5% 0 5%;
    clip-path: polygon(0 0%, 100% 0, 100% 100%, 0% 100%);
  }
}