.SimpleMap {
    height: 85vh;
    clip-path: polygon( 0 3%, 48.5% 0, 100% 2.5%, 100% 98.65%, 48.87% 94.85%, 0 100% );
}

#map {
    height: 80%;
    width: 100%;
}
.leaflet-container {
    height: 80vh;
    width: 100wh;
}

.marker,
.my-marker {
    margin-top: -11px;
    margin-left: -5.5px;
    padding: 4px;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    position: relative;
    cursor: pointer;
}

.my-marker {
    margin-top: -20px;
    margin-left: -10px;
    padding: 2px;
    cursor: inherit;
}

.marker:hover {
    background-color: #fff;
}

@media (max-width: 991px) {
    .SimpleMap {
        height: 85vh;
        clip-path: polygon( 45% 3.5%, 78% 0%, 100% 3%, 100% 100%, 0% 100%, 0% 3%, 19% 0% );
    }
}
